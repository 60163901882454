import React from 'react';

interface Props {}

const Catering: React.FC<Props> = () => {
  return (
    <div className={`Catering`}>
      <p>
        Sie lieben das Besondere? Sie möchten in Heilbronn ein Familienfest
        ausrichten, ohne den ganzen Tag in der Küche schuften zu müssen? Ihnen
        steht eine Silberhochzeit, ein runder Geburtstag oder eine Taufe ins
        Haus? Sie haben eine Firmenveranstaltung geplant und sich noch nicht um
        die Verpflegung der Teilnehmer gekümmert? Sie treffen im Businesspark
        Schwabenhof auf wichtige Geschäftspartner und möchten diese zum Essen
        einladen?
      </p>
      <p>
        Überraschen Sie Ihre Gäste doch mal mit einer bunten Auswahl von
        Köstlichkeiten aus der tamilischen Küche!
      </p>
    </div>
  );
};

export default Catering;
