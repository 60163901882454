import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageFluidProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import Catering from 'organisms/Catering';

import 'styles/pages/catering.scss';

export const CATERING_PAGE_QUERY = graphql`
  query CateringPageQuery {
    logo: file(relativePath: { eq: "logos/thamarai-catering-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageFluidProps;
  };
}

interface Props {}

const CateringPage: React.FC<Props> = () => {
  const { logo } = useStaticQuery<Data>(CATERING_PAGE_QUERY);

  return (
    <Layout
      className="CateringPage"
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/catering-service"
          canonical="/catering-service"
          title="Event Catering"
          lang="de-DE"
        />
      )}
      header={() => (
        <Layout.Header
          title="Catering by THAMARAI - Indische Spezialitäten in Heilbronn"
          subtitle="Privates Catering, Firmenanlässe, Hochzeiten"
          image={logo.childImageSharp}
          alt="Exklusives Logo für die Catering-Seite"
        />
      )}
    >
      <Catering />
    </Layout>
  );
};

export default CateringPage;
